<template>
  <section v-if="company">
    <div class="col-8">
      <CiHeading level="2" class="mb-2">
        {{ $t("settings.units") }}
      </CiHeading>
      <RdxSelect
        :value="getCurrency(company.currency)"
        :options="currencies"
        :disabled="pending.load || pending.save"
        vm-track-by="code"
        :label="$t('settings.currency')"
        :placeholder="$t('settings.select-currency')"
        :custom-label="
          option => {
            return `${option.code} (${option.name})`;
          }
        "
        :allow-empty="false"
        data-cy="currency-select"
        @input="setCurrency"
      />
      <RdxSelect
        v-model="company.mileage_unit"
        :options="options.mileage_units"
        :disabled="pending.load || pending.save"
        :label="$t('settings.mileage')"
        :placeholder="$t('settings.select-unit')"
        :custom-label="option => $t(`global.mileage_units.${option}`)"
        :allow-empty="false"
      />
      <RdxSelect
        v-model="company.engine_capacity_unit"
        :options="options.engine_capacity_units"
        :disabled="pending.load || pending.save"
        :label="$t('settings.engine-capacity')"
        :placeholder="$t('settings.select-unit')"
        :custom-label="option => $t(`global.engine_capacity_unit.${option}`)"
        :allow-empty="false"
      />
      <RdxSelect
        v-model="company.power_unit"
        :options="options.power_units"
        :disabled="pending.load || pending.save"
        :label="$t('settings.engine-power')"
        :placeholder="$t('settings.select-unit')"
        :custom-label="option => $t(`global.power_unit.${option}`)"
        :allow-empty="false"
      />
      <RdxButton
        filled
        :disabled="pending.load || pending.save"
        class="mt-4"
        @click="saveSettings"
      >
        {{ $t("global.save") }}
      </RdxButton>
    </div>
  </section>
</template>

<script>
import CURRENCIES from "./currencies.json";
import { RdxSelect, RdxButton } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "CarsInventorySettingsUnits",

  components: {
    RdxSelect,
    CiHeading,
    RdxButton
  },

  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    selectedCompany: {
      type: Object,
      default: () => ({})
    },
    pending: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      currencies: CURRENCIES,
      company: { ...this.selectedCompany.attributes }
    };
  },

  watch: {
    selectedCompany() {
      this.company = { ...this.selectedCompany.attributes };
    }
  },

  methods: {
    setCurrency(currency) {
      this.company.currency = currency ? currency.code : null;
    },
    getCurrency(currency_code) {
      return this.currencies.find(({ code }) => currency_code === code);
    },

    saveSettings() {
      this.$emit("saveSettings", this.company);
    }
  }
};
</script>
